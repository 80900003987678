'use client';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function SnowEffect() {
  const [flakes, setFlakes] = useState([]);

  useEffect(() => {
    const createFlakes = () =>
      Array.from({ length: 100 }, (_, id) => ({
        id,
        left: Math.random() * 100,
        size: Math.random() * 4 + 2,
        opacity: Math.random() * 0.3 + 0.1,
        delay: Math.random() * 5,
        speed: Math.random() * 10 + 20,
      }));
    setFlakes(createFlakes());
    const interval = setInterval(() => setFlakes(createFlakes()), 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="relative bg-green-50 px-4 py-16 md:py-24 overflow-hidden">
      <div className="relative max-w-6xl mx-auto text-center">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 mb-8">
          Eco-conscious Progress with ATORIX - Leading SAP Service Provider
        </h1>
        <div className="max-w-4xl mx-auto text-gray-700 text-lg mb-12 space-y-6">
          <p>
            What is your Go-Green Quotient? How deep-seated is sustainability in your Enterprise Value Chain? One of the crucial aspects of becoming a responsible entrepreneur is meeting environmental regulations and reporting requirements. Sustainability is a prime consideration in all human activity for ATORIX - a leading <span className="font-semibold">SAP Service Provider</span>.
          </p>
          <p>
            We stand by our motto of focused innovation, where technological progression and energy generation are in harmony with the environment. SAP + ATORIX - Together we aim to take People and Planet together.
          </p>
          <p>
            Ready to be unlocked with ATORIX Chasing Zero Journey with SAP Solutions?
          </p>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          <Link to="/contact">
            <button className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-3 rounded-md transition duration-200 flex items-center">
              <span className="mr-2">&rarr;</span> SAP BUSINESS ONE
            </button>
          </Link>
          <Link to="/get-demo">
            <button className="border-2 border-gray-800 hover:bg-gray-800 hover:text-white text-gray-800 px-8 py-3 rounded-md transition duration-200 flex items-center">
              <span className="mr-2">&rarr;</span> TALK TO SERVICE PROVIDER
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
