// import React from "react";
// import Footer from "../components/Footer";
// import NavBar from "../components/Navbar/NavBar"; // Adjust the import path based on your project structure
// import { Link } from "react-router-dom";

// const ProductPage = () => {
//   return (
//     <div>
//       <div>
//         <NavBar />
//       </div>
// {/* {Introduction Section} */}

// <div className="pt-20 px-6 mt-20">
//         {/* Introduction Section */}
//         <section className="text-center bg-gray-50 py-10 rounded-lg shadow-md">
//           <h1 className="text-4xl font-bold text-gray-800 mb-4">
//             Explore Our Premium Products
//           </h1>
//           <p className="text-lg text-gray-600 leading-relaxed">
//             Welcome to our product page, where innovation meets quality. Discover our range of 
//             top-notch products designed to cater to your needs and enhance your lifestyle.
//           </p>
//         </section>


// </div>




//       {/* Portfolio Section */}
//       <div className="my-4 py-4 mt-20" id="portfolio">
//         <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold mt-10">
//           Products & Solutions
//         </h2>
//         <div className="flex justify-center">
//           <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
//         </div>

//         <div className="px-4" data-aos="fade-down" data-aos-delay="600">
//           <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-5">
//             <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
//               <div className="m-2 text-justify text-sm">
//                 <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
//                   SAP Functional & Technical
//                 </h4>
//                 <p className="text-md font-medium leading-5">
//                   SAP technical modules are primarily focused on ensuring a
//                   smooth-operating SAP landscape. SAP Basis – SAP Basis is
//                   considered the system administration of SAP systems and
//                   provides the technical foundation enabling SAP applications to
//                   run smoothly. SAP Security – SAP Security establishes robust
//                   internal security to allow or prevent access where needed. SAP
//                   NetWeaver – SAP NetWeaver connects business users to SAP
//                   software in real-time regardless of their access point (social
//                   media platforms, mobile devices, or web applications).
//                 </p>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
//               <div className="m-2 text-justify text-sm">
//                 <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
//                   SAP ABAP Development
//                 </h4>
//                 <p className="text-md font-medium leading-5">
//                   Atorix ABAP developers acquire well-built technical and
//                   analytical skills in development. Our development encounter
//                   ranges from the core SAP modules to claim-to-fame regions, for
//                   example, SAP HR, SD, HCM, FICO, MM, SD, PP & PM. Our SAP ABAP
//                   developers create inventive solutions to support your SAP
//                   infrastructure. We provide onsite and remote development
//                   services at a cost-effective price. Our remote development
//                   arrangement enables clients to spend IT budgets on value-added
//                   development instead of paying consultant expenses.
//                 </p>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
//               <div className="m-2 text-justify text-sm">
//                 <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
//                   SAP BASIS & SECURITY
//                 </h4>
//                 <p className="text-md font-medium leading-5">
//                   BASIS, which stands for Business Application Software
//                   Integrated Solution, provides a well-structured technical
//                   foundation for SAP systems. It acts as an essential middleware
//                   layer, bridging the gap between operating systems, databases,
//                   communication protocols, and SAP applications such as FI
//                   (Financial Accounting), CO (Controlling), MM (Materials
//                   Management), SD, HR, HCM, PP, and PM. BASIS is crucial for
//                   ensuring seamless communication between SAP applications,
//                   regardless of the underlying system environment.
//                 </p>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
//               <div className="m-2 text-justify text-sm">
//                 <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
//                   SAP S/4 HANA Solution
//                 </h4>
//                 <p className="text-md font-medium leading-5">
//                   SAP S/4HANA Cloud is a Software as a Service (SaaS) edition of
//                   S/4HANA. It’s available to enterprises both in public and
//                   private clouds and enables users to leverage S/4HANA
//                   functionality without any need for hardware, a database, or
//                   internal IT staff. In S/4HANA Cloud, SAP will provide and
//                   handle almost everything for the customer, including
//                   applications, data, runtime, middleware, operating system,
//                   servers, storage, and networking.
//                 </p>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
//               <div className="m-2 text-justify text-sm">
//                 <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
//                   SAP Implementation & Support
//                 </h4>
//                 <p className="text-md font-medium leading-5">
//                   SAP Implementation and Support are critical services that help
//                   organizations leverage the full potential of SAP systems. SAP
//                   implementation involves the strategic deployment of SAP
//                   software to meet a company's specific business requirements,
//                   encompassing core modules like FI (Financial Accounting), CO
//                   (Controlling), MM (Materials Management), SD (Sales and
//                   Distribution), HR, HCM, PP, and PM. This process requires
//                   expert planning, configuration, customization, and integration
//                   to ensure seamless workflow alignment across all business
//                   functions.
//                 </p>
//               </div>
//             </div>

//             <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
//               <div className="m-2 text-justify text-sm">
//                 <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
//                   SAP Software Services
//                 </h4>
//                 <p className="text-md font-medium leading-5">
//                   Traditional business models often decentralize data
//                   management, with each business function storing its own
//                   operational data in a separate database. This makes it
//                   difficult for employees from different business functions to
//                   access each other’s information. Furthermore, duplication of
//                   data across multiple departments increases IT storage costs
//                   and the risk of data errors.
//                 </p>
//               </div>
//             </div>
//           </div>

//           {/* Button added here */}
//           <div className="flex justify-center my-4">
//             <Link
//               to="/get-demo"
//               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-4 py-2 text-base shadow-md rounded-md"
//             >
//               Schedule Demo
//               <svg
//                 className="w-3 h-3 ml-1"
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//                 fill="currentColor"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M10.293 3.293a1 1 0 011.414 0l6 6a  "
//                 />
//               </svg>
//             </Link>
//           </div>
//         </div>
//       </div>

//       {/* Footer Section */}
//       <Footer />
//     </div>
//   );
// };

// export default ProductPage;




import React, { useState, useEffect, useCallback } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar/NavBar";
import { Link } from "react-router-dom";
import SnowEffect from "../components/Snoweffect";

const stats = [
  { label: "CUSTOMERS", value: 1800, suffix: "+" },
  { label: "TEAM", value: 1400, suffix: "+" },
  { label: "LOCATIONS", value: 12, suffix: "+" },
  { label: "REVENUE", value: 750, suffix: "+Cr" },
];

const CountUp = ({ end, duration = 2000, suffix = "" }) => {
  const [count, setCount] = useState(0);

  const easeOutQuad = (t) => t * (2 - t);

  const animate = useCallback(() => {
    const startTime = Date.now();

    const updateCount = () => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / duration, 1);
      setCount(Math.floor(end * easeOutQuad(progress)));

      if (progress < 1) requestAnimationFrame(updateCount);
    };

    requestAnimationFrame(updateCount);
  }, [end, duration]);

  useEffect(() => {
    animate();
  }, [animate]);

  return (
    <span className="font-extrabold text-blue-900">
      {count}
      {suffix}
    </span>
  );
};

const ProductCard = ({ title, description, hoverSummary, isHovered, onHover }) => {
  return (
    <div
      className={`relative p-6 bg-white shadow-lg rounded-lg border border-gray-200 transition-all duration-300 ${
        isHovered ? "bg-gradient-to-r from-blue-50 to-blue-100 shadow-2xl scale-105" : ""
      }`}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
    >
      <h4 className="text-xl font-semibold text-blue-900 mb-4 text-center">{title}</h4>
      <p className={`text-gray-700 text-center ${isHovered ? "hidden" : "block"}`}>{description}</p>
      {isHovered && (
        <p className="text-blue-600 font-bold text-center transition-opacity duration-300">
          {hoverSummary}
        </p>
      )}
      <div
        className={`absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-900 opacity-0 ${
          isHovered ? "opacity-20" : ""
        } transition-opacity duration-500`}
      ></div>
    </div>
  );
};

const useIntersectionObserver = (setInView) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const element = document.querySelector(".fade-in-section");
    if (element) observer.observe(element);

    return () => observer.disconnect();
  }, [setInView]);
};

const ProductPage = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [inView, setInView] = useState(false);

  useIntersectionObserver(setInView);

  const products = [
    { title: "SAP Functional & Technical", description: "Our SAP services integrate and maintain systems.", hoverSummary: "Seamless integration." },
    { title: "SAP ABAP Development", description: "Custom SAP solutions tailored to needs.", hoverSummary: "Tailored development." },
    { title: "SAP BASIS & SECURITY", description: "Secure and optimize SAP middleware.", hoverSummary: "Robust security." },
    { title: "SAP S/4 HANA Solution", description: "Digital transformation with SAP.", hoverSummary: "Real-time insights." },
    { title: "SAP Implementation & Support", description: "Smooth deployment and ongoing assistance.", hoverSummary: "Expert support." },
    { title: "SAP Software Services", description: "Centralized automation for business processes.", hoverSummary: "Efficient automation." },
  ];

  return (
    <div className="bg-gray-50">
      <NavBar />
      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center mt-20">
        <div
          className="absolute inset-0 bg-cover bg-center z-0"
          style={{ backgroundImage: "url('/placeholder.svg?height=1080&width=1920')" }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900/80 to-gray-900/40" />
        </div>

        <div className="container mx-auto px-4 z-10 text-center">
          <h1 className="text-5xl md:text-6xl font-extrabold text-white mb-6 animate-fadeIn">
            Unlock Seamless Digital Evolution with Tailored ERP SAP Services
          </h1>
          <p className="text-lg md:text-xl text-gray-200 mb-8 fade-in-section">
            Transform your business with cutting-edge SAP solutions customized for your needs.
          </p>
          <Link to="/contact">
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg text-lg shadow-md transition-transform transform hover:scale-105">
              GET SAP DEMO
            </button>
          </Link>
        </div>
      </div>

      {/* Products Section */}
      <div className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className={`text-4xl font-bold text-center text-blue-900 mb-8 ${inView ? "animate-fadeInUp" : ""}`}>
            Products & Solutions
          </h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                title={product.title}
                description={product.description}
                hoverSummary={product.hoverSummary}
                isHovered={hoveredCard === index}
                onHover={() => setHoveredCard(hoveredCard === index ? null : index)}
              />
            ))}
          </div>
          <div className="flex justify-center mt-10">
            <Link
              to="/get-demo"
              className="px-6 py-3 bg-blue-900 text-white text-lg font-medium rounded-lg hover:bg-blue-800 shadow-md transition-all duration-300"
            >
              Schedule Demo
            </Link>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative bg-gradient-to-b from-slate-900 to-slate-800 py-16 text-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-extrabold mb-6 animate-fadeIn">
            Technology Enablers, Automation Partners
          </h2>
          <p className="text-lg text-gray-300 mb-10">
            Empowering progressive businesses through technology solutions.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-extrabold mb-2">
                  <CountUp end={stat.value} suffix={stat.suffix} />
                </div>
                <div className="text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <SnowEffect />
      <Footer />
    </div>
  );
};

export default ProductPage;
